<template>
	<!--  申请资料 详情 -->
	<div class="box">
		<case-details @getCaseDetailsData="getCaseDetailsData" :caseStates="'view_detail'">
			<template slot="titleBtn">
				<a-button type="primary" @click="SendAudit">{{ $t('operate.sendAudit') }}</a-button>
				<a-button type="primary" @click="Archive" style="margin-left: 20px">{{ $t('page.archive') }}</a-button>
			</template>
		</case-details>
		<a-modal v-drag-modal :title="$t('table.applyInfo.titleMap.title1')" v-model="Modal.show" :row="6" @ok="handleOk">
			<a-textarea v-model="Modal.reason" :maxLength="300" />
		</a-modal>
		<!-- <a-modal v-drag-modal width="60%" v-model="creditRueModal" ok-text="Submit" cancel-text="Cancel" @ok="handleSubmit">
			<template slot="title"> <a-icon type="warning" /> {{ $t('table.loanReview.riskWarning') }}</template>
			<a-table :columns="columns" :rowKey="(record, index) => index" :pagination="false" :data-source="creditResponse.creditRueList" />
			<div class="rec-loan">
				<p v-if="!$Util.isEmpty(creditResponse.totalCreditAmount)"
					>{{ `${$t('table.loanReview.recommendLoanAmount')}` }}: <a-statistic :precision="2" :value="creditResponse.totalCreditAmount"
				/></p>
				<p v-if="!$Util.isEmpty(creditResponse.dueDateLimit)">{{ $t('table.loanReview.recommendLoanPeriod') }}: {{ creditResponse.dueDateLimit }}</p>
			</div>
		</a-modal> -->
	</div>
</template>
<script>
import { Verification } from '@/utils/Verification'
import { apiProcessCheck, apiProcessFile } from '@/api/check'
import CaseDetails from '@/views/businessComponents/CaseDetails'
export default {
	data() {
		return {
			id: this.$route.query.id,
			Modal: {
				show: false,
				reason: ''
			},
			columns: [
				{
					title: 'No.',
					dataIndex: 'creditCode',
					width: 150,
					align: 'center',
					customRender: (text, row, index) => {
						return index + 1
					}
				},
				{
					title: 'Warning',
					dataIndex: 'creditDesc',
					align: 'center'
				}
			],
			creditRueModal: false,
			creditResponse: '',
			caseDetailsData: ''
		}
	},
	components: {
		CaseDetails
	},
	methods: {
		closeTab(targetKey) {
			this.$store.commit('tabs/delTab', targetKey)
			this.$store.commit('tabs/setActiveTab', this.$route.query.from)
		},
		getCaseDetailsData(val) {
			if (val.creditReport.idNumber == null) {
				this.$message.warning('Credit Report Empty!')
			}
			this.caseDetailsData = val
		},
		Archive() {
			this.Modal.reason = ''
			this.Modal.show = true
		},
		handleOk() {
			if (this.Modal.reason) {
				apiProcessFile({
					loanId: this.id,
					checkText: this.Modal.reason
				}).then((res) => {
					this.$message.success('success')
					this.closeTab(this.$route.fullPath)
					this.$router.push(this.$route.query.from)
				})
			} else {
				this.$message.error('reason is required!')
				return false
			}
		},
		SendAudit() {
			const verificationBool = Verification(
				this.caseDetailsData.financeInfo,
				this.caseDetailsData.loanApplyInfo,
				this.caseDetailsData.loanCarInfos,
				this.caseDetailsData.loanInfo,
				this.caseDetailsData.personalInfo,
				this.caseDetailsData.creditReport,
				this.caseDetailsData.creatingInfo
			)
			if (verificationBool) {
				let num = 0
				this.caseDetailsData.financeInfo.transcations.map((item) => {
					if (item.status != 3) {
						num++
					}
				})
				if (num > 0) {
					this.$message.error(this.$t(`table.applyInfo.msgMap.msg4`))
					return false
				} else {
					this.$store.commit('pageState/setUploadState', true)
					apiProcessCheck({
						loanId: this.id,
						pass: 1,
						checkType: 'submit',
						// needCredit: 1,
						checkText: '',
						checkFailType: '',
						nodeId: this.caseDetailsData.processStatus.processNode.id
					})
						.then((res) => {
							this.$message.success('success')
							this.closeTab(this.$route.fullPath)
							this.$router.push(this.$route.query.from)
							// this.creditResponse = res
							// this.creditRueModal = true
						})
						.finally(() => {
							this.$store.commit('pageState/setUploadState', false)
						})
				}
			}
		}
		// handleSubmit() {
		// 	apiProcessCheck({
		// 		loanId: this.id,
		// 		pass: 1,
		// 		checkType: 'submit',
		// 		// needCredit: 0,
		// 		checkText: '',
		// 		checkFailType: '',
		// 		nodeId: this.caseDetailsData.processStatus.processNode.id
		// 	}).then(() => {
		// 		this.$message.success('success')
		// 		this.closeTab(this.$route.fullPath)
		// 		this.$router.push(this.$route.query.from)
		// 	})
		// }
	}
}
</script>
<style scoped lang="less">
textarea.ant-input {
	height: 100px;
}
.anticon-warning {
	font-size: 28px;
	color: red;
	vertical-align: middle;
	margin-right: 10px;
}
.rec-loan {
	p {
		margin: 15px 0;
		font-size: 16px;
		.ant-statistic {
			display: inline-block;
		}
	}
}
</style>
