import { Message } from 'ant-design-vue'

export const Verification = (financeInfo, loanApplyInfo, loanCarInfos, loanInfo, personalInfo, creditReport, creatingInfo) => {
	const regEmail = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
	const carReg = /^[A-Za-z]{3} ?[0-9]{3}[A-Za-z]$/
	const identityCardRegex = /^[A-Za-z0-9]{8}$/
	const pinRege = /^[A-Za-z0-9]{11}$/
	const mobileReg = /^\d{9}$|^0\d{9}$/

	// 贷款申请校验
	if (!loanInfo.applyAmount) {
		Message.error('Total loans must input')
		return false
	}

	// 创建信息
	if (creatingInfo) {
		if (
			!creatingInfo.cityCode ||
			!creatingInfo.cityName ||
			!creatingInfo.storeId ||
			!creatingInfo.storeName ||
			!creatingInfo.salesmanId ||
			!creatingInfo.salesmanName
		) {
			Message.error('creatingInfo error')
			return false
		}
	}

	// 个人资料校验
	if (
		!personalInfo.basicFirstName ||
		!personalInfo.basicLastName ||
		!personalInfo.customerIdPic ||
		!personalInfo.identityCardBack ||
		!personalInfo.identityCardFront ||
		!personalInfo.birthday ||
		!personalInfo.loanUse
	) {
		Message.error('Personal Data Basic Information error')
		return false
	}
	let mobileBool = true
	for (let index = 0; index < personalInfo.mobiles.length; index++) {
		const element = personalInfo.mobiles[index]
		if (!mobileReg.test(element.mobile)) {
			console.log(mobileReg.test(element))
			Message.error(`Personal Data the ${index + 1} Mobile number error`)
			mobileBool = false
			break
		}
	}
	if (mobileBool === false) {
		return false
	}
	if (!personalInfo.basicIdentityCard) {
		Message.error('Personal Data National ID error')
		return false
	}
	if (
		!personalInfo.pin.effectiveTime ||
		!personalInfo.pin.itaxObligation ||
		!personalInfo.pin.itaxStatus ||
		!personalInfo.pin.taxpayerName ||
		!personalInfo.pin.status
	) {
		Message.error('Personal Data KRA PIN error')
		return false
	}
	if (!pinRege.test(personalInfo.pin.pinNum)) {
		Message.error('Personal Data KRA PIN number error')
		return false
	}
	if (personalInfo.incomeSource.employerEmail) {
		if (!regEmail.test(personalInfo.incomeSource.employerEmail)) {
			Message.error('email employerEmail error')
			return false
		}
	}
	if (personalInfo.incomeSource.employerMobile) {
		if (!mobileReg.test(personalInfo.incomeSource.employerMobile)) {
			Message.error('personalInfo employerMobile error')
			return false
		}
	}

	// 车辆资料校验
	let loanCarInfoBool = true
	if (loanInfo.loanType !== 7) {
		for (let index = 0; index < loanCarInfos.length; index++) {
			const element = loanCarInfos[index]
			if (
				element.pic.appearancePics.length == 0 &&
				element.pic.interiorPics.length == 0 &&
				element.pic.chassisPics.length == 0 &&
				element.pic.enginePics.length == 0 &&
				element.pic.detailPics.length == 0
			) {
				Message.error(`Vehicle Information ${index + 1} Vehicle photo error`)
				loanCarInfoBool = false
				break
			}
			// if (!element.logbook.logbookFile) {
			// 	Message.error(`Vehicle Information ${index + 1} logbook logbookFile error`)
			// 	loanCarInfoBool = false
			// 	break
			// }
			// if (!element.logbook.make || !element.logbook.model || !element.logbook.year || !element.logbook.frameNumber || !element.logbook.engineNo) {
			// 	Message.error(`Vehicle Information ${index + 1} logbook basic error`)
			// 	loanCarInfoBool = false
			// 	break
			// }
			// if (!carReg.test(element.logbook.registrationNumber)) {
			// 	Message.error(`Vehicle Information ${index + 1} logbook Registration Number error`)
			// 	loanCarInfoBool = false
			// 	break
			// }
			// if (element.logbook.belongs.length == 0) {
			// 	Message.error(`Vehicle Information ${index + 1} logbook belongs error`)
			// 	loanCarInfoBool = false
			// 	break
			// }
			if (
				!element.valuation.make ||
				!element.valuation.model ||
				!element.valuation.year ||
				!element.valuation.frameNumber ||
				!element.valuation.engineNo ||
				!element.valuation.valuation ||
				!element.valuation.reportTime ||
				!element.valuation.saleValuation
			) {
				Message.error(`Vehicle Information ${index + 1} valuation basic error`)
				loanCarInfoBool = false
				break
			}
			// if (element.valuation.registrationNumber) {
			// 	if (!element.logbook.logbookFile) {
			// 		Message.error(`Vehicle Information ${index + 1} logbook logbookFile error`)
			// 		loanCarInfoBool = false
			// 		break
			// 	}
			// 	if (!element.logbook.make || !element.logbook.model || !element.logbook.year || !element.logbook.frameNumber || !element.logbook.engineNo) {
			// 		Message.error(`Vehicle Information ${index + 1} logbook basic error`)
			// 		loanCarInfoBool = false
			// 		break
			// 	}
			// 	if (!carReg.test(element.logbook.registrationNumber)) {
			// 		Message.error(`Vehicle Information ${index + 1} logbook Registration Number error`)
			// 		loanCarInfoBool = false
			// 		break
			// 	}
			// 	if (element.logbook.belongs.length == 0) {
			// 		Message.error(`Vehicle Information ${index + 1} logbook belongs error`)
			// 		loanCarInfoBool = false
			// 		break
			// 	}
			// }
			// if (!carReg.test(element.valuation.registrationNumber)) {
			// 	Message.error(`Vehicle Information ${index + 1} valuation Registration Number error`)
			// 	loanCarInfoBool = false
			// 	break
			// }
		}
	}
	if (loanCarInfoBool === false) {
		return false
	}

	// 财务资料验证
	if (loanInfo.loanType !== 4) {
		if (financeInfo.transcations.length == 0) {
			Message.error('Financial Information Bank/Mpesa Statements error')
			return false
		}
	}
	if (financeInfo.loanType == 0) {
		let loanTypeZeroCarInfoBool = true
		for (let index = 0; index < loanCarInfos.length; index++) {
			const element = loanCarInfos[index]
			if (!element.logbook.logbookFile) {
				Message.error(`Vehicle Information ${index + 1} logbook logbookFile error`)
				loanTypeZeroCarInfoBool = false
				break
			}
			if (!element.logbook.make || !element.logbook.model || !element.logbook.year || !element.logbook.frameNumber || !element.logbook.engineNo) {
				Message.error(`Vehicle Information ${index + 1} logbook basic error`)
				loanTypeZeroCarInfoBool = false
				break
			}
			if (!element.logbook.registrationNumber) {
				Message.error(`Vehicle Information ${index + 1} logbook Registration Number error`)
				loanTypeZeroCarInfoBool = false
				break
			}
			if (element.logbook.belongs.length == 0) {
				Message.error(`Vehicle Information ${index + 1} logbook belongs error`)
				loanTypeZeroCarInfoBool = false
				break
			}
			if (!element.valuation.registrationNumber) {
				Message.error(`Vehicle Information ${index + 1} valuation Registration Number error`)
				loanTypeZeroCarInfoBool = false
				break
			}
		}
		if (loanTypeZeroCarInfoBool === false) {
			return false
		}
	}
	if (financeInfo.loanType == 1) {
		let loanTypeOneCarInfoBool = true
		for (let index = 0; index < loanCarInfos.length; index++) {
			const element = loanCarInfos[index]
			if (element.valuation.registrationNumber || element.logbook.registrationNumber || element.logbook.logbookFile) {
				if (!element.logbook.logbookFile) {
					Message.error(`Vehicle Information ${index + 1} logbook logbookFile error`)
					loanTypeOneCarInfoBool = false
					break
				}
				if (!element.logbook.make || !element.logbook.model || !element.logbook.year || !element.logbook.frameNumber || !element.logbook.engineNo) {
					Message.error(`Vehicle Information ${index + 1} logbook basic error`)
					loanTypeOneCarInfoBool = false
					break
				}
				if (!element.logbook.registrationNumber) {
					Message.error(`Vehicle Information ${index + 1} logbook Registration Number error`)
					loanTypeOneCarInfoBool = false
					break
				}
				if (element.logbook.belongs.length == 0) {
					Message.error(`Vehicle Information ${index + 1} logbook belongs error`)
					loanTypeOneCarInfoBool = false
					break
				}
			}
		}
		if (loanTypeOneCarInfoBool === false) {
			return false
		}
		if (!financeInfo.carAmount) {
			Message.error('Financial Information Car Purchase Amount error')
			return false
		}
		if (!financeInfo.sellerInstitution) {
			Message.error('Financial Information Company Name error')
			return false
		}
		if (!financeInfo.sellerAccount) {
			Message.error('Financial Information Account NO error')
			return false
		}
	}

	// 申请资料验证
	if (!loanApplyInfo.applyFile) {
		Message.error('Application Information Loan Application Form error')
		return false
	}
	if (!loanApplyInfo.applyAmount) {
		Message.error('Application Information Loan Application Form Application Amount error')
		return false
	}
	if (!loanApplyInfo.installmentsNumbers) {
		Message.error('Application Information Loan Application Form Application Period error')
		return false
	}
	if (loanApplyInfo.relatives.length == 0) {
		Message.error('Application Information relatives error')
		return false
	}
	if (loanApplyInfo.hasGuarantor == 1) {
		if (!loanApplyInfo.guarantor.guarantorForm) {
			Message.error("Application Information Gurantor's Form error")
			return false
		}
		if (!loanApplyInfo.guarantor.firstName || !loanApplyInfo.guarantor.lastName) {
			Message.error('Application Information Gurantor basic error')
			return false
		}
		if (!mobileReg.test(loanApplyInfo.guarantor.mobile)) {
			Message.error('Application Information Gurantor Mobile number error')
			return false
		}
		if (!loanApplyInfo.guarantor.identityCard) {
			Message.error('Application Information Gurantor National ID error')
			return false
		}
		if (!loanApplyInfo.guarantor.identityCardFrontPic || !loanApplyInfo.guarantor.identityCardBackPic) {
			Message.error('Application Information Gurantor basic pic error')
			return false
		}
		if (!loanApplyInfo.guarantor.photo) {
			Message.error('Application Information Gurantor Photo error')
			return false
		}
		if (!pinRege.test(loanApplyInfo.guarantor.pin.pinNum)) {
			Message.error('Application Information Gurantor KRA PIN pinNum error')
			return false
		}
		if (
			!loanApplyInfo.guarantor.pin.itaxObligation ||
			!loanApplyInfo.guarantor.pin.itaxStatus ||
			!loanApplyInfo.guarantor.pin.effectiveTime ||
			!loanApplyInfo.guarantor.pin.status ||
			!loanApplyInfo.guarantor.pin.taxpayerName
		) {
			Message.error('Application Information Gurantor KRA PIN basic error')
			return false
		}
	}

	// 征信报告
	// if (creditReport) {
	// 	if (creditReport.totalOutstandingBalance === '' || creditReport.totalOutstandingBalance === null) {
	// 		Message.error('creditReport error')
	// 		return false
	// 	}
	// } else {
	// 	Message.error('creditReport error')
	// 	return false
	// }

	return true
}
